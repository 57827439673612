import { Script } from 'gatsby';
import GTM from './GTM';

const Scripts = ({ env }) => {
  const friendBuyScript = `
    window["friendbuyAPI"] = friendbuyAPI = window["friendbuyAPI"] || [];

    friendbuyAPI.merchantId = "65cf869f-1fea-46b9-b80e-fa97dcc5b790";
    friendbuyAPI.push(["merchant", friendbuyAPI.merchantId]);

    (function(f, r, n, d, b, u, y) {
      while ((u = n.shift())) {
        (b = f.createElement(r)), (y = f.getElementsByTagName(r)[0]);
        b.async = 1;
        b.src = u;
        y.parentNode.insertBefore(b, y);
      }
    })(document, "script", [
      "https://static.fbot.me/friendbuy.js",
      "https://campaign.fbot.me/" + friendbuyAPI.merchantId + "/campaigns.js",
    ]);
  `;

  return (
    <>
      <Script
        type={'text/javascript'}
        defer
        dangerouslySetInnerHTML={{
          __html: GTM,
        }}
        onError={() => {
          // eslint-disable-next-line no-console
          console.error(`failure loading GTM`);
        }}
      />
      <Script
        key="friendBuy"
        type={'text/javascript'}
        onLoad={() => console.log(`success loading friendBuy`)}
        onError={() => console.log(`failure loading friendBuy`)}
        dangerouslySetInnerHTML={{ __html: friendBuyScript }}
      />
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-PSNPXVM"
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
          title="gtm"
        ></iframe>
      </noscript>
    </>
  );
};

export default Scripts;
