import {
  PaymentOptions,
  getPaymentOptions,
} from '@customer/services/pandoService';
import { DataModel, dataModel } from '@customer/state/store/helpers/dataModel';
import { StoreModel } from '@customer/state/store/model';
import { persist, ThunkOn, thunkOn } from 'easy-peasy';

export interface PaymentOptionsFetchPayload {
  billingDay: string;
}

export interface PaymentOptionsModel
  extends DataModel<PaymentOptions, any, PaymentOptionsFetchPayload> {
  onAccountsSetCurrentId: ThunkOn<PaymentOptionsModel, any, StoreModel>;
}
const paymentOptions = persist<PaymentOptionsModel>(
  {
    ...dataModel(
      async (payload, helpers) => {
        const { billingDay } = payload || {};
        const state = helpers.getStoreActions();
        const storeState = helpers.getStoreState();
        const currentId = storeState?.activeId?.currentId || localStorage.getItem('currentId');
        if (currentId === null) {
          console.error('accounts is missing currentId');
          return [];
        }
        const accountGuid =
          storeState?.pando?.accounts?.data?.[currentId]?.AcctId;

        if (accountGuid == null) {
          console.error(`account (${currentId}) is missing accountGuid`);
          return [];
        }

        const accessToken = await state.pando.getToken();
        if (accessToken == null) {
          console.error(`pando model missing accessToken`);
          return [];
        }

        const response = await getPaymentOptions(
          { accountGuid, billingDay: billingDay || '' },
          { accessToken }
        );

        return [{ ...response?.data?.Data, AcctId: currentId }];
      },
      {
        idKey: 'AcctId',
      }
    ),
    onAccountsSetCurrentId: thunkOn(
      (actions, storeActions) => storeActions.activeId.setCurrentId,
      async (actions, target, helpers) => {
        const storeState = helpers.getStoreState();
        if (storeState?.pando?.accounts?.ids?.length) {
          actions.resetMemorizeInitialFetch();
          actions.initialFetch();
        }
      }
    ),
  },
  { deny: ['error'] }
);
export default paymentOptions;
