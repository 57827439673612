import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CartProvider, useCart } from '@shared/cart-react';
import config from '@customer/utils/config';
import datoLoad from '@customer/utils/datoLoad';
import { calculateTotals } from '@customer/services/coveService';
import { useStoreState, useStoreActions } from '@customer/hooks/useStore';
import useIsLoggedIn from '@customer/hooks/useIsLoggedIn';
import store from '@customer/state/store';

const saveCart = async (cartId, body) => {
  await store.persist.resolveRehydration();
  const isLoggedIn = store.getState()?.user?.isLoggedIn;
  const accountGuid = store.getState()?.pando?.accounts?.currentId;
  const coveCreditBalance =
    store.getState()?.account?.coveCreditBalance?.data?.[accountGuid || '']
      ?.coveCreditBalance;

  if (!isLoggedIn || !accountGuid) {
    return body;
  }

  const coveCredit = (() => {
    if (
      !body?.applyCoveCreditAmount ||
      Number.isNaN(parseFloat(`${body?.applyCoveCreditAmount}`)) ||
      Number.isNaN(parseFloat(`${coveCreditBalance}`))
    ) {
      return 0;
    }

    return Math.max(
      0,
      Math.min(
        Number(parseFloat(`${coveCreditBalance}`).toFixed(2)),
        Number(parseFloat(`${body.applyCoveCreditAmount}`).toFixed(2))
      )
    );
  })();

  const result = await calculateTotals(
    {
      accountGuid,
      cartProducts: Object.values(body.cartProducts),
      promoCode: body.promoCode,
      couponCodes: body.couponCodes,
      coveCredit,
    },
    {
      accessToken: await store.getActions()?.pando?.getToken?.(),
    }
  );

  if (result?.data) {
    result.data.cartId = cartId;
  }
  return result;
};

const SyncCart: React.FC<{}> = () => {
  const isLoggedIn = useIsLoggedIn();
  const accountGuid = useStoreState(state => state?.pando?.accounts?.currentId);
  const cart = useCart();
  useEffect(() => {
    if (isLoggedIn) {
      cart?.saveCart?.();
    }
  }, [accountGuid, isLoggedIn, !cart]);
  const [cartAccountGuid, setCartAccountGuid] = useState(accountGuid);
  useEffect(() => {
    if (!cartAccountGuid && cartAccountGuid !== accountGuid) {
      setCartAccountGuid(accountGuid);
    }

    if (cartAccountGuid && cartAccountGuid !== accountGuid) {
      cart?.clear?.();
      setCartAccountGuid(accountGuid);
    }
  }, [cartAccountGuid, setCartAccountGuid, accountGuid]);

  return null;
};

const CustomerCartProvider: React.FC<{ partnerId: string }> = ({
  partnerId,
  children,
  ...props
}) => {
  return (
    <CartProvider
      partnerId={partnerId}
      apiV4Url={config.COVE_API_V4_URL}
      datoData={datoLoad({ partnerId })}
      saveCart={saveCart}
      shippingMethod="Regular"
      prefixLocalStorageKey={`CUSTOMER_PORTAL_`}
    >
      <SyncCart />
      {children}
    </CartProvider>
  );
};

export default CustomerCartProvider;
